import { Component, OnInit, Input } from '@angular/core';
import { AppConstants } from '../../app-constants';
import { BoardSquare } from '../../models/board-square.model';
import { trigger, state, style, transition, animate } from '@angular/animations';

const animationTime = '500ms cubic-bezier(0.35, 0, 0.25, 1)';

@Component({
  selector: 'app-square',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.css'],
  animations: [
    trigger('flyIn', [
      state('in', style({
        transition: 'translateX(0)'
      })),
      transition('void => *', [
        style({ transform: 'translateX(-300%)' }),
        animate(animationTime)
      ])
    ]),
  ]
})
export class SquareComponent implements OnInit {

  @Input() square: BoardSquare;

  constructor() { }

  ngOnInit() {
  }

  pickedByFirstPlayer(): boolean {
    return this.square.content === AppConstants.firstPlayerSymbol;
  }

  pickedBySecondPlayer(): boolean {
    return this.square.content === AppConstants.secondPlayerSymbol;
  }

  isWinningSquare(): boolean {
    return this.square.winning;
  }
}
