import { Component, OnInit, HostListener } from '@angular/core';
import { BoardSquare } from '../../models/board-square.model';
import { AppConstants } from '../../app-constants';
import { HttpClient } from '@angular/common/http';
import { GameBoard } from './game-board';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
  squares: BoardSquare[] = [
    new BoardSquare(0),
    new BoardSquare(1),
    new BoardSquare(2),
    new BoardSquare(3),
    new BoardSquare(4),
    new BoardSquare(5),
    new BoardSquare(6),
    new BoardSquare(7),
    new BoardSquare(8)
  ];
  message: string;
  isFirstPlayersTurn: boolean;
  isGameOver = false;
  isGameAgainstComputer = false;
  isComputersTurn = false;
  picksSequence: number[] = [];

  constructor(private http: HttpClient) {
    this.startNewGame();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);
    let number = 0;
    if (event.keyCode) {
      number = event.keyCode - 48;
    } else {
      number = +event.key;
    }

    if (number > 0 && number < 10) {
      this.onPick(this.squares[number - 1]);
    }
  }

  ngOnInit() {
  }

  startNewGame() {
    this.isGameAgainstComputer = false;
    this.initGame(AppConstants.playerOneTurnMessage);
  }

  async startNewGameAgainstComputer() {
    this.isGameAgainstComputer = true;
    const isComputerStarting = this.getRandomInt(2) === 1;
    const message = isComputerStarting ? AppConstants.computersTurnMessage : AppConstants.humansTurnMessage;
    this.initGame(message);
    if (isComputerStarting) {
      this.isComputersTurn = isComputerStarting;
      await this.computerPick();
    }
  }

  initGame(message: string) {
    this.squares.forEach(element => {
      element.content = undefined;
      element.winning = false;
    });
    this.isFirstPlayersTurn = true;
    this.message = message;
    this.isGameOver = false;
    this.picksSequence = [];
  }

  async computerPick() {
    await new Promise(r => setTimeout(r, 1000)); // let the computer think
    var gameBoard: GameBoard = new GameBoard();
    gameBoard.composition = this.getCurrentComposition(); 
    const nextPick : any = await this.http.post('https://fx-tictactoe-service.azurewebsites.net/api/tictactoe/getnextpick', gameBoard).toPromise();
    // const nextPick : any = await this.http.post('http://localhost:5000/api/tictactoe/getnextpick', gameBoard).toPromise();
    const index = parseInt(nextPick.composition[nextPick.composition.length-1], 10);
    this.picksSequence.push(index);
    this.squares[index].content = this.isFirstPlayersTurn ? AppConstants.firstPlayerSymbol : AppConstants.secondPlayerSymbol;
    if (this.isGameOverNow()) {
      return;
    }

    this.isFirstPlayersTurn = !this.isFirstPlayersTurn;
    this.isComputersTurn = false;
    this.message = AppConstants.humansTurnMessage;
  }

  getCurrentComposition() : string {
    var result = '';
    this.picksSequence.forEach(x => result+=x);
    return result;
  }

  async onPick(square: BoardSquare) {
    if (square.content !== undefined || this.isGameOver || this.isComputersTurn) {
      return;
    }

    square.content = this.isFirstPlayersTurn ? AppConstants.firstPlayerSymbol : AppConstants.secondPlayerSymbol;
    if (this.isGameOverNow()) {
      return;
    }

    this.isFirstPlayersTurn = !this.isFirstPlayersTurn;
    this.picksSequence.push(square.index);
    if (this.isGameAgainstComputer) {
      this.isComputersTurn = true;
      this.message = AppConstants.computersTurnMessage;
      await this.computerPick();
    } else {
      this.message = this.isFirstPlayersTurn ? AppConstants.playerOneTurnMessage : AppConstants.playerTwoTurnMessage;
    }
  }

  isGameOverNow(): boolean {
    if (this.checkForWinner(AppConstants.firstPlayerSymbol)) {
      this.message = this.isGameAgainstComputer
      ? this.isComputersTurn ? AppConstants.computerWinsMessage : AppConstants.humanWinsMessage
      : AppConstants.playerOneWinsMessage;
      this.isGameOver = true;
      return true;
    }

    if (this.checkForWinner(AppConstants.secondPlayerSymbol)) {
      this.message = this.isGameAgainstComputer
      ? this.isComputersTurn ? AppConstants.computerWinsMessage : AppConstants.humanWinsMessage
      : AppConstants.playerTwoWinsMessage;
      this.isGameOver = true;
      return true;
    }

    if (this.allSquaresPicked()) {
      this.message = AppConstants.tieMessage;
      this.isGameOver = true;
      return true;
    }

    return false;
  }

  checkForWinner(content: string): boolean {
    // rows
    for (let index = 0; index < 3; index++) {
      if (this.checkRow(index, content)) {
        this.markRow(index);
        return true;
      }
    }

    // columns
    for (let index = 0; index < 3; index++) {
      if (this.checkColumn(index, content)) {
        this.markColumn(index);
        return true;
      }
    }

    // diagonals
    if (this.checkDiagonalBottomLeftoToUpperRight(content)) {
      this.markDiagonalBottomLeftoToUpperRight();
      return true;
    }
    if (this.checkDiagonalUpperLeftToBottomRight(content)) {
      this.markDiagonalUpperLeftToBottomRight();
      return true;
    }

    return false;
  }

  checkRow(i: number, content: string): boolean {
    return this.squares[0 + i * 3].content === content
      && this.squares[1 + i * 3].content === content
      && this.squares[2 + i * 3].content === content;
  }

  checkColumn(i: number, content: string): boolean {
    return this.squares[i + 0].content === content
    && this.squares[i + 3].content === content
    && this.squares[i + 6].content === content;
  }

  checkDiagonalBottomLeftoToUpperRight(content): boolean {
    return (this.squares[2].content === content && this.squares[4].content === content && this.squares[6].content === content);
  }

  checkDiagonalUpperLeftToBottomRight(content): boolean {
    return (this.squares[0].content === content && this.squares[4].content === content && this.squares[8].content === content);
  }

  allSquaresPicked(): boolean {
    return this.squares.every(x => x.content !== undefined);
  }

  markRow(i: number) {
    this.squares[0 + i * 3].winning = true;
    this.squares[1 + i * 3].winning = true;
    this.squares[2 + i * 3].winning = true;
  }

  markColumn(i: number) {
    this.squares[i].winning = true;
    this.squares[i + 3].winning = true;
    this.squares[i + 6].winning = true;
  }

  markDiagonalBottomLeftoToUpperRight() {
    this.squares[2].winning = true;
    this.squares[4].winning = true;
    this.squares[6].winning = true;
  }

  markDiagonalUpperLeftToBottomRight() {
    this.squares[0].winning = true;
    this.squares[4].winning = true;
    this.squares[8].winning = true;
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }
}
