import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BoardSquare } from '../../models/board-square.model';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent {
  @Input() squares: BoardSquare[];
  @Output() pick: EventEmitter<BoardSquare> = new EventEmitter<BoardSquare>();

  constructor() { }

  picked(square: BoardSquare) {
    this.pick.emit(square);
  }
}
