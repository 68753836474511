import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {

  @Input() highlightColor = 'black';
  @Input() highlightBackgroundColor = 'yellow';

  constructor(private el: ElementRef) { }

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor, this.highlightBackgroundColor);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null, null);
  }

  private highlight(color: string, background: string) {
    this.el.nativeElement.style.color = color;
    this.el.nativeElement.style.backgroundColor = background;
  }
}
