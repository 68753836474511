export class AppConstants {
    public static firstPlayerSymbol = 'X';
    public static secondPlayerSymbol = 'O';
    public static playerOneWinsMessage = 'Player 1 wins!!!';
    public static playerTwoWinsMessage = 'Player 2 wins!!!';
    public static playerOneTurnMessage = `It's player 1 turn.`;
    public static playerTwoTurnMessage = `It's player 2 turn.`;
    public static tieMessage = `It's a tie!!!`;
    public static humansTurnMessage = `It's your turn.`;
    public static computersTurnMessage = `It's computer's turn.`;
    public static humanWinsMessage = `You win!!!`;
    public static computerWinsMessage = `Computer wins!!!`;
}
